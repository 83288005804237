import React, { Component } from 'react';
import ServicesData from '../ServicesData';

export default class Volunteer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: "All",
    };
  }

  render() {
    return (
      <body>
        <div className="text-center container_format p-3">
          <h1 className="container_text">Government Services</h1>
        </div>

        <section id="courses" className="courses">
          <div className="container overflow-hidden" data-aos="fade-up">
            <div className="row mb-3 justify-content-end">
              {/* Align the dropdown to the right */}
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="text-end">
                  <select
                    className="form-select"
                    value={this.state.selectedCategory}
                    onChange={(e) =>
                      this.setState({ selectedCategory: e.target.value })
                    }
                  >
                    <option value="All">All Categories</option>
                    <option value="Health">Health</option>
                    <option value="Food">Food</option>
                    <option value="Housing">Housing</option>
                    <option value="Finances">Finances</option>
                    <option value="Transport">Transport</option>
                    <option value="Education">Education</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row" data-aos="zoom-in" data-aos-delay="100">

              {/* Loop through the filtered card data and generate cards */}
              {ServicesData.filter(
                (card) =>
                  this.state.selectedCategory === 'All' ||
                  card.category === this.state.selectedCategory
              ).map((card) => (
                <div
                  key={card.id}
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-2 mt-lg-0"
                >
                  <div className="course-item position-relative">
                    <div className="category-label">{card.category}</div>
                    <img
                      src={card.img}
                      className="img-fluid"
                      alt={card.title}
                    />
                    <div className="course-content">
                      <h3>
                        <a href={card.link}>{card.title}</a>
                      </h3>
                      <p>{card.desc}</p>
                      <div className="mt-auto">
                        <a href={card.link} className="more-btn">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </body>
    );
  }
}