import React, { Component, useEffect } from "react";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, useLocation, Routes } from "react-router-dom";
import Home from "./components/home";
import Navbar from './components/navbar';
import Footer from './components/footer';
import About from './components/about';
import Volunteer from './components/volunteer';
import Services from './components/services';
import Resources from './components/resources';


class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <div className=".container-fluid-custom">
          <Navbar />
          <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/about" element={<About/>}/>
            <Route exact path="/volunteer" element={<Volunteer/>}/>
            <Route exact path="/services" element={<Services/>}/>
            <Route exact path="/resources" element={<Resources/>}/>
          </Routes>
          <Footer />
        </div>
      </Router>
    );
  }
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
