import React, { Component } from 'react';


export default class Volunteer extends Component {
  render() {
    return (
      <body>
        <div class="text-center container_format p-3">
          <h1 class="container_text">Volunteer Events in Baltimore</h1>
        </div>
        <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&src=OWhpYjVsOWdhMmhxM2gwN2d0dG1zNjU0MDhAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237CB342" width="100%" height="600" frameborder="0" scrolling="no"></iframe>
      </body>
    )
  }
}