const ServicesData = [
  {
    id: "1",
    title: "Medicare",
    category: "Health",
    desc: "Medicare is a federal medical insurance program for people aged 65+, younger patients with disabilities, and all people with End-Stage Renal Disease. People receiving medicare do pay yearly deductibles and often pay 20% of the costs for the services they receive. Different plans exist and costs can vary.",
    img: "images/medicare.jpg",
    link: "https://www.medicare.gov/what-medicare-covers/your-medicare-coverage-choices/whats-medicare",
  },
  {
    id: "2",
    title: "Medicaid",
    category: "Health",
    desc: "Medicaid is the largest provider of health insurance to Americans. This program serves eligible recipients regardless of age and can be used with other services (such as medicare). In the case of Maryland, medicaid generally covers everything from hospital visits to prescription drugs - all at little to no cost. See the official page for a list of services that are covered (click the 'Medicaid' caption above).",
    img: "images/hands.jpg",
    link: "https://www.marylandhealthconnection.gov/after-you-enroll/medicaid/what-medicaid-covers/",
  },
  {
    id: "3",
    title: "SNAP Benefits",
    category: "Food",
    desc: "SNAP provides funding to help people afford food items. This is done through the issuance of a SNAP card, which can be used like a credit card at locations that accept it. SNAP benefits vary based on the net household income and have limits based on household size. There are also some rules regarding eligibility such as work requirements, and certain food items cannot be purchased with these benefits.",
    img: "images/vegetables.jpg",
    link: "https://dhs.maryland.gov/supplemental-nutrition-assistance-program/applying-for-the-food-supplement-program/",
  },
  {
    id: "4",
    title: "Public Housing and RAD",
    category: "Housing",
    desc: "The Housing Authority of Baltimore City offers affordable housing to low-income families and individuals through the Public Housing program. The Rental Assistance program (RAD) is also available to help these groups find and afford housing. Please note that some rent is paid by recipients in either case.",
    img: "images/apartment.jpg",
    link: "https://www.habc.org/habc-information/programs-departments/public-housing-and-rad/",
  },
  {
    id: "5",
    title: "Temporary Cash Assistance",
    category: "Finances",
    desc: "Temporary Cash Assistance (TCA), Maryland’s Temporary Assistance to Needy Families (TANF) program, provides cash assistance to families with dependent children when available resources do not fully address the family’s needs and while preparing program participants for independence through work.",
    img: "images/money.jpg",
    link: "https://dhs.maryland.gov/weathering-tough-times/temporary-cash-assistance/",
  },
  {
    id: "6",
    title: "Housing Choice Voucher Program",
    category: "Housing",
    desc: "This voucher program is offered in several counties in Maryland, as well as Baltimore City, to help with housing costs. This is based on household income. There are sub-divisions of this program offered in Baltimore as well.",
    img: "images/hcvp.jpg",
    link: "https://www.habc.org/habc-information/programs-departments/hcvp/",
  },
  {
    id: "7",
    title: "Unemployment Insurance",
    category: "Finances",
    desc: "This program provides financial assistance to people who are unemployed and meet certain eligibility requirements. An expectation is that this program will provide temporary assistance until a new place of employment is found by the recipient.",
    img: "images/unemployment_insurance.jpg",
    link: "https://www.dllr.state.md.us/employment/claimfaq.shtml",
  },
  {
    id: "8",
    title: "Homelessness Prevention Program",
    category: "Housing",
    desc: "This program provides one-time grants to help recipients avoid eviction. Moreover, counseling services are provided to help throughout this process. Clients are also trained such that the necessary skills are learned to avoid facing a similar situation in the future.",
    img: "images/homelessness_prevention.jpg",
    link: "https://dhs.maryland.gov/weathering-tough-times/homelessness-prevention-program/",
  },
  {
    id: "9",
    title: "Earned Income Tax Credit",
    category: "Finances",
    desc: "While not a “program” in the sense that this section has generally covered, this credit can help individuals with low to moderate incomes save some money. There is a federal version of this program as well, but a state-level one is also available to Maryland residents. This can reduce, or even eliminate, the amount of income tax owed to the state.",
    img: "images/income_tax.jpg",
    link: "https://dhs.maryland.gov/weathering-tough-times/earned-income-tax-credit/",
  },
  {
    id: "10",
    title: "Emergency Assistance to Families with Children",
    category: "Finances",
    desc: "This program gives emergency cash to families so they can pay for rent, utilities, or other emergency situations. Families receiving aid must have a child under 21, face the emergency not because of having quit a job, and must provide proof of the emergency – in addition to meeting other eligibility requirements. An Emergency Assistance Form can be filled out online.",
    img: "images/family.jpg",
    link: "https://dhs.maryland.gov/weathering-tough-times/emergency-assistance/",
  },
  {
    id: "11",
    title: "Maryland Medical Assistance Programs",
    category: "Health",
    desc: "Several programs exist to address numerous situations including ensuring that children (up to age 19) from disadvantaged families are medically insured, providing supplemental assistance to Medicare coverage, and covering nursing home costs. Eligibility is generally decided based on income level relative to the federally-defined poverty level.",
    img: "images/medical.jpg",
    link: "https://dhs.maryland.gov/weathering-tough-times/medical-assistance/",
  },
  {
    id: "12",
    title: "Energy and Water Assistance",
    category: "Finances",
    desc: "The Office of Home Energy Programs (OHEP) provides bill assistance to low-income households in the State of Maryland. Specifically, they provide grants covering utility bills – including heating, electric and gas bills. Assistance for past-due bills is also available. Programs also exist that help pay water bills, ensure that heating is not lost during the winter months, and to aid with making home repairs.",
    img: "images/energy.jpg",
    link: "https://dhs.maryland.gov/office-of-home-energy-programs/",
  },
  {
    id: "13",
    title: "Public Assistance to Adults",
    category: "Finances",
    desc: "This program pays for the “cost of care and a personal needs allowance for needy individuals who live in assisted living facilities licensed by the Maryland Department of Health (MDH) and in Certified Adult Residential Environmental homes (also known as Project Home). PAA pays only the personal needs allowance to needy people in MDH Rehabilitative Residences.",
    img: "images/hospice.jpg",
    link: "https://dhs.maryland.gov/weathering-tough-times/public-assistance-adults/",
  },
  {
    id: "14",
    title: "Temporary Disability Assistance",
    category: "Finances",
    desc: "The Temporary Disability Assistance Program (TDAP) is available to help low-income, disabled Marylanders through a period of short-term disability or while they are awaiting approval of federal disability support. Please remember to apply for the federal disability support if you suffer from a chronic disability.",
    img: "images/wheelchair.jpg",
    link: "https://dhs.maryland.gov/weathering-tough-times/temporary-disability-assistance/",
  },
  {
    id: "15",
    title: "Transportation Assistance Program",
    category: "Transport",
    desc: "Through a network of partners across the state, TAP provides reliable used vehicles that will serve eligible low-income families for two years or 24,000 miles. This is meant to help people advance their careers or take higher-paying jobs for which they are qualified but otherwise unable to reach due to lacking transportation.",
    img: "images/driving.jpg",
    link: "https://dhs.maryland.gov/weathering-tough-times/transportation-assistance-program-tap/",
  },
  {
    id: "16",
    title: "Burial Assistance Program",
    category: "Finances",
    desc: "This program provides financial assistance to families who need help paying for burial and funeral costs. It is designed to ensure that Marylanders can afford a proper burial for their loved ones, even in difficult financial circumstances.",
    img: "images/coffin.jpg",
    link: "https://dhs.maryland.gov/weathering-tough-times/burial-assistance/",
  },
  {
    id: "17",
    title: "Pharmaceutical Assistance Programs",
    category: "Health",
    desc: "Maryland residents have several programs available to them that are dedicated to ensuring all people can receive the medications they need. The requirements vary per program - detailed information can be found by following the link above.",
    img: "images/drugs.jpg",
    link: "https://health.maryland.gov/phpa/OIDPCS/AVHPP/Pages/pharmaceutical-assistance.aspx",
  },
  {
    id: "18",
    title: "Maryland Legal Aid",
    category: "Finances",
    desc: "Provides legal services to low-income and marginalized individuals who face civil legal problems but cannot afford a lawyer.",
    img: "images/lawyer.jpg",
    link: "https://www.needhelppayingbills.com/html/free_legal_advice_maryland_leg.html",
  },
  {
    id: "19",
    title: "Meals on Wheels",
    category: "Food",
    desc: "This program is largely operated by volunteers and provides meals to senior citizens and people with disabilities throughout Maryland. They also consider dietary restrictions, with options pork-free as well as texture-controlled options available.",
    img: "images/tomatoes.jpg",
    link: "https://www.mealsonwheelsmd.org/",
  },
  {
    id: "20",
    title: "FAFSA",
    category: "Education",
    desc: "The Free Application for Federal Student Aid is an assistance program that provides funding to students for attending college or career school in the United States. Assistance can come in the form of grants, work-study, or loans. In the case of loans, the applicant is responsible for repaying the amount accrued after the education is completed, with monthly payments generally beginning 6-months post-graduation.",
    img: "images/college_student.jpg",
    link: "https://studentaid.gov/apply-for-aid/fafsa/filling-out/help",
  },
  // Add more card objects as needed
];
 
export default ServicesData;