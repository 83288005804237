import React, { Component } from 'react';
import Map from "./map";


export default class Resources extends Component {
  render() {
    return (
      <component>
        <div class="text-center container_format p-3">
          <h1 class="container_text">Find Resources</h1>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-9">
              <div style={{padding: "1%"}}>
                <div id="map">
                  <Map />
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div id="test" style={{overflowY:"scroll", height:"600px", padding: "1%"}}></div>
            </div>
          </div>
        </div>               
      </component>
    )
  }
}