import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from "react-router-dom";


export default class Home extends Component {
  render() {
    return (
      <div>
      <section id="hero" class="d-flex justify-content-center align-items-center">
        <div class="container position-relative" data-aos="zoom-in" data-aos-delay="100">
          <h1>Finding Resources in Baltimore,<br/>Made Easy</h1>
          <h2>Clinics, Food Pantries, and Shelters</h2>
          <Link to="/resources" class="btn-get-started">Find Now</Link>
        </div>
      </section>
      <main id="main">
        <section id="why-us" class="why-us">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-lg-4 d-flex align-items-stretch">
              <div class="content">
                <h3>Empowerment through access</h3>
                <p>
                  Nobody asks for challenging times, but when they hit, finding help shouldn't add to the pain. Numerous services exist - and we've found them so you don't have to.
                </p>
                <div class="text-center">
                <Link to="/about" class="more-btn">Learn More <i class="bx bx-chevron-right"></i></Link>
                </div>
              </div>
            </div>
            <div class="col-lg-8 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div class="icon-boxes d-flex flex-column justify-content-center">
                <div class="row">
                  <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box mt-4 mt-xl-0">
                      <div>
                        <img src="images/yellow_clinic.png" class="img-fluid" style={{width: '50%',  height: "auto", paddingBottom: "10%"}}/>
                      </div>
                      <h4>Free Clinics</h4>
                      <p>Healthcare costs can be scary, but there are options! Numerous free/affordable clinics exist in Baltimore.</p>
                    </div>
                  </div>
                  <div class="col-xl-4 d-flex align-items-stretch">
                    <div class="icon-box mt-4 mt-xl-0">
                      <div>
                        <img src="images/yellow_food_pantry.png" class="img-fluid" style={{width: '50%',  height: "auto", paddingBottom: "10%"}}/>
                      </div>
                      <h4>Food Pantries</h4>
                      <p>Nobody should have to go hungry, and many organizations exist to ensure no one has to in Baltimore.</p>
                     </div>
                    </div>
                    <div class="col-xl-4 d-flex align-items-stretch">
                      <div class="icon-box mt-4 mt-xl-0">
                        <div>
                          <img src="images/yellow_shelter.png" class="img-fluid" style={{width: '50%',  height: "auto", paddingBottom: "10%"}}/>
                        </div>
                        <h4>Shelters</h4>
                        <p>Whether in need of financial relief or physical housing, there are many organizations that can help.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>                
        <section id="counts" class="counts section-bg">
          <div class="container">
            <div class="row counters">
              <div class="col-lg-4 col-6 text-center">
                <CountUp start={0} end={47} duration={1} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                <p>Clinics</p>
              </div>
                <div class="col-lg-4 col-6 text-center">
                  <CountUp start={0} end={23} duration={1} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <p>Food Pantries</p>
                </div>
                <div class="col-lg-4 col-6 text-center">
                  <CountUp start={0} end={13} duration={1} redraw={true}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                  <p>Shelters</p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    )
  }
}