import React, { Component } from 'react';
import { Link } from "react-router-dom";


export default class Footer extends Component {
  render() {
    return (
      <footer class="site-footer">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <h6>About</h6>
              <p class="text-justify">Finding free resources when you need them should be simple. We aim to empower the residents of Baltimore City through access to government resources and non-profit services. We believe this is best accomplished through providing information - informed people make informed choices.</p>
            </div>
            <div class="col-xs-6 col-md-3">
              <h6>Reources</h6>
              <ul class="footer-links">
                <li><Link to="/services">Government Services</Link></li>
                <li><Link to="/resources">Find Clinics</Link></li>
                <li><Link to="/resources">Find Food Pantries</Link></li>
                <li><Link to="/resources">Find Shelters</Link></li>
              </ul>
            </div>
            <div class="col-xs-6 col-md-3">
              <h6>Quick Links</h6>
              <ul class="footer-links">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/services">Government Services</Link></li>
                <li><Link to="/volunteer">Volunteer</Link></li>
                <li><Link to="/resources">Resource Map</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <hr/>
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
              <p class="copyright-text">Copyright &copy; 2023 All Rights Reserved by&nbsp;
                <a href="#">Baltimore CARE Network</a>.
              </p>
            </div>
          </div>
        </div>
      </footer>    
    )
  }
}