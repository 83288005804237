import React, { useRef, useEffect } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl, {Marker} from "!mapbox-gl";
import geoJson from "../map.json";
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxGeocoder from "mapbox-gl-geocoder";
import "mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";


mapboxgl.accessToken =
  "pk.eyJ1IjoicHJpbWUwMjUiLCJhIjoiY2xoNWxsejZ5MjhoMjNmcG1obnBqdTAzdSJ9.F327sT70N4noN6UFmYBqgA";

const Map = () => {
  const mapContainerRef = useRef(null);
  const cardRefs = useRef([]);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-76.612190, 39.290386],
      zoom: 11,
    });

    // Add geocoder control
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
    });

    map.addControl(geocoder);

    var element = document.getElementById("test");

    const createNew = (content) => {
      var row_div = document.createElement("div");
      row_div.className = 'row';
      row_div.style.cssText = 'padding: 1%'

      var card_div = document.createElement("div");
      card_div.className = 'card';
      card_div.id = `card-${content.properties.title.replace(/ /g, '')}`;

      var card_body_div = document.createElement("div");
      card_body_div.className = 'card-body';

      var card_body_h5 = document.createElement("h5");
      card_body_h5.className = 'card-title';
      var text = document.createTextNode(content.properties.title);
      card_body_h5.append(text);

      var card_body_h6 = document.createElement("h6");
      card_body_h6.classList.add('card-subtitle');
      card_body_h6.classList.add('mb-2');
      card_body_h6.classList.add('text-muted');
      var text2 = document.createTextNode(content.type);
      card_body_h6.append(text2);

      var card_body_phone = document.createElement("p");
      card_body_phone.className = 'card-text';
      var text3 = document.createTextNode(content.properties.phone_number);
      card_body_phone.append(text3);
      
      var card_body_dir_link = document.createElement("a");
      card_body_dir_link.className = 'card-link';
      var text3 = document.createTextNode('Directions');
      card_body_dir_link.setAttribute('href', "https://www.google.com/maps/dir/Baltimore+MD/" + content.properties.title.replace(' ', '+') + content.properties.address.replace(' ', '+'));
      card_body_dir_link.append(text3);

      var card_body_web_link = document.createElement("a");
      card_body_web_link.className = 'card-link';
      var text3 = document.createTextNode('Website');
      card_body_web_link.setAttribute('href', content.properties.website_link);
      card_body_web_link.append(text3);

      card_body_div.append(card_body_h5);
      card_body_div.append(card_body_h6);
      card_body_div.append(card_body_phone);
      card_body_div.append(card_body_dir_link);
      card_body_div.append(card_body_web_link);

      card_div.append(card_body_div);
      row_div.append(card_div);
      element.append(row_div);
      cardRefs.current.push(card_div);
  }

  // Create default markers
  geoJson.features.forEach((feature) => {
    const markerElement = document.createElement('div');
    markerElement.className = 'marker';
    
    createNew(feature)

    if(feature.type == 'Clinic'){
      markerElement.style.backgroundImage = 'url(images/clinic.jpg)';
    }
    else if (feature.type == 'Shelter'){
      markerElement.style.backgroundImage = 'url(images/house.png)';
    }
    else if (feature.type == 'Food Pantry'){
      markerElement.style.backgroundImage = 'url(images/food.png)';
    }
      
    const marker = new mapboxgl.Marker(markerElement)
    .setLngLat(feature.geometry.coordinates)
    .setPopup(
      new mapboxgl.Popup({ offset: 25 }).setHTML(
        `<h3>${feature.properties.title}</h3>
        <p>${feature.properties.description}</p>
        <p>${feature.properties.address}</p>
        <p>${feature.properties.phone_number}</p>`
      )
    )
    .addTo(map);

  // Add event listener to marker
  marker.getElement().addEventListener("click", () => {
    // Retrieve the corresponding card_div reference
    const cardId = `#card-${feature.properties.title.replace(/ /g, '')}`;
    const cardDiv = document.querySelector(cardId);

    // Scroll the card_div into view
    //cardDiv.style.border = "2px solid red"; // Add temporary CSS style
    const parentDiv = document.getElementById("test");
    parentDiv.scrollTo({
      top: cardDiv.offsetTop - parentDiv.offsetTop,
      behavior: "smooth",
    });
  });
});

    // Clean up on unmount
    return () => {
      map.remove();
    };
  }, []);

  return <div className="map-container" ref={mapContainerRef} />;
};

export default Map;