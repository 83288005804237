import React, { Component } from 'react';


export default class About extends Component {
  render() {
    return (           
      <body>
        <div class="text-center container_format p-3">
          <h1 class="container_text">About Us</h1>
        </div>
        <section id="why-us" class="why-us">
          <div class="container" data-aos="fade-up">
            <div class="row" style={{textAlign: 'center', fontSize: 'large', marginBottom: '15px'}}>
              <p>We aim to empower the residents of Baltimore City by providing an up-to-date and comprehensive collection of available resources while also connecting volunteers with service opportunities in the city.</p>
            </div>
            <div class="row">
              <div class="col-lg-5 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                <img src="images/about_us_summary.png" class="img-fluid" alt=""/>
              </div>
              <div class="col-lg-7 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div class="row">   
                  <div class="row" style={{marginTop: '10px'}}>
                    <div class="card" style={{color: 'white', backgroundColor: '#131d30'}}>
                      <div class-="card-body">
                        <h2 class="card-title" style={{marginTop: '15px', textAlign: 'center', fontWeight: '900'}}>Who We Are</h2>
                        <p class="card-body">Baltimore CARE Network is a website brought to you by two local UMBC graduates with a passion for serving the Baltimore community. We want to see a better city, with fewer inequities and less hardship.</p>
                      </div>
                    </div>
                  </div>
                  <div class="row" style={{marginTop: '10px'}}>
                    <div class="card" style={{color: 'white', backgroundColor: '#131d30'}}>
                      <div class-="card-body">
                        <h2 class="card-title" style={{marginTop: '15px', textAlign: 'center', fontWeight: '900'}}>Our Why</h2>
                        <p class="card-body">It was through our volunteer experiences that we realized just how prevalent food and housing insecurities, as well as unaddressed medical needs, are in many parts of Baltimore. This led us to our next question: what services exist to address these needs, and how can we volunteer with them to make a difference? We did a deep dive and found several NGO's, government agencies, and other non-profit organizations - a long and time-consuming endeavor. We want to make searching for help and finding volunteer opportunities easier through BRF, so you can quickly scan various services and choose which ones may meet your needs and volunteer your time as well.</p>
                      </div>
                    </div>
                  </div>
                  <div class="row" style={{marginTop: '10px'}}>
                    <div class="card" style={{color: 'white', backgroundColor: '#131d30'}}>
                      <div class-="card-body">
                        <h2 class="card-title" style={{marginTop: '15px', textAlign: 'center', fontWeight: '900'}}>Our Mission</h2>
                        <p class="card-body">We aim to empower through access and service. We believe this is best accomplished through providing information - informed people make informed choices.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    )
  }
}