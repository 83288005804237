import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavbarReact from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default class MyNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
  }

  handleMenuToggle = () => {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  };

  render() {
    const { isMenuOpen } = this.state;

    return (
      <NavbarReact expand="lg" variant="dark">
        <NavbarReact.Brand as={Link} to="/">
          Baltimore CARE Network
        </NavbarReact.Brand>
        <NavbarReact.Toggle aria-controls="basic-navbar-nav" onClick={this.handleMenuToggle} />
        <NavbarReact.Collapse className={`${isMenuOpen ? 'show' : ''}`} id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About Us
            </Nav.Link>
            <Nav.Link as={Link} to="/volunteer">
              Volunteer
            </Nav.Link>
            <Nav.Link as={Link} to="/services">
              Government Services
            </Nav.Link>
          </Nav>
          <Nav className={`${isMenuOpen ? '' : 'ms-auto resource-button'}`}>
            <Nav.Link as={Link} to="/resources">
              Resources
            </Nav.Link>
          </Nav>
        </NavbarReact.Collapse>
      </NavbarReact>
    );
  }
}